<template>
  <div>
    <QCReceiptBasket v-if="!idCommand" @onCreated="onCreated" />
    <div v-else>
      <v-row dense>
        <v-col cols="6">
          <QCProcess :id-command="idCommand" type="passed" />
        </v-col>
        <v-col cols="6">
          <QCProcess :id-command="idCommand" type="failed" />
        </v-col>
        <v-col cols="12">
          <v-btn
            color="purple"
            class="white--text"
            block
            small
            @click="confirmCommand"
          >
            {{ $t("labels.finish") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- <QCProcess v-else :id-command="idCommand" /> -->
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "QC",
  components: {
    QCReceiptBasket: () => import("@/components/goods/QCReceiptBasket"),
    QCProcess: () => import("@/components/goods/QCProcess"),
  },
  data: () => ({
    idCommand: null,
    isLoading: false,
  }),
  mounted() {
    this.getActiveCommand();
  },
  methods: {
    async getActiveCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/get-active-command"
        );
        this.idCommand = (data && data.id) || null;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async confirmCommand() {
      if (!confirm(this.$t("messages.are_you_sure"))) {
        return false;
      }

      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/quality-control/v1/confirm-command", {
          id: this.idCommand,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.commandDone();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    onCreated(data) {
      this.idCommand = (data && data.id) || null;
    },
    commandDone() {
      this.idCommand = null;
    },
  },
};
</script>

<style scoped></style>
